import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';

import PrimaryButton from '../PrimaryButton';
import { HeroTextWrapper, H1 } from './styles';

const HeroText = ({ title, subTitle, ctaText, ctaAction, noPad }) => {
  return (
    <HeroTextWrapper pt={noPad ? 1 : 'auto'}>
      <Grid container spacing={noPad ? 0 : 4}>
        <Grid item xs={12}>
          <H1>{title}</H1>
        </Grid>
        <Grid item xs={12}>
          {subTitle ? (
            <Typography variant="h5" component="h2">
              <Box color="white">{subTitle}</Box>
            </Typography>
          ) : null}
          {ctaText ? (
            <Box pt={!subTitle ? '3.5em' : '2em'}>
              <PrimaryButton onClick={ctaAction} danger>
                {ctaText}
              </PrimaryButton>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </HeroTextWrapper>
  );
};

export default HeroText;
