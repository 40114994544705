import styled from 'styled-components';
import { Box } from '@material-ui/core';

const HeroImage = styled(Box).attrs((props) => ({
  width: '100%',
  minHeight: 300,
}))`
  && {
    ${(props) =>
      props.backgroundimage
        ? `background-image: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)),url(${props.backgroundimage});`
        : `background-color: #1A60B2`}
    /* Position and center the image to scale nicely on all screens */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
`;

export default HeroImage;
