import React from 'react';

import { Avatar, Box } from '@material-ui/core';

import HeroImage from './HeroImage';
import HeroText from './HeroText';

import { getHeroImageWidth } from '../utils';

// https://getwaves.io/

// <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fill-opacity="1" d="M0,128L80,112C160,96,320,64,480,85.3C640,107,800,181,960,213.3C1120,245,1280,235,1360,229.3L1440,224L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path></svg>
// <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fill-opacity="1" d="M0,160L80,160C160,160,320,160,480,170.7C640,181,800,203,960,213.3C1120,224,1280,224,1360,224L1440,224L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path></svg>

const BookingHero = ({
  heroImageSrc,
  profileImageSrc,
  title,
  subTitle,
  ctaText,
  ctaAction,
}) => {
  return (
    <HeroImage
      width="100%"
      minHeight={480}
      bgcolor="#1A60B2"
      color="white"
      textAlign="center"
      backgroundimage={getHeroImageWidth(heroImageSrc, 800)}
      display="flex"
      flexDirection="column"
    >
      {profileImageSrc ? (
        <Box
          pt={4}
          display="flex"
          alignContent="center"
          justifyContent="center"
        >
          <Avatar
            alt={title}
            src={`https://agrddpxdbm.cloudimg.io/v7/${profileImageSrc}?w=120&h=120`}
            style={{
              height: '120px',
              width: '120px',
            }}
          />
        </Box>
      ) : null}
      <HeroText
        noPad={!!profileImageSrc}
        title={title}
        subTitle={subTitle}
        ctaText={ctaText}
        ctaAction={ctaAction}
      />
    </HeroImage>
  );
};

export default BookingHero;
