import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';

export const HeroTextWrapper = styled(Box).attrs((props) => ({
  color: 'white',
  textAlign: 'center',
  position: 'relative',
  mx: 'auto',
  p: '4%',
  maxWidth: '600px',
}))``;

export const H1 = styled(Typography).attrs((props) => ({
  variant: 'h2',
  component: 'h1',
  gutterBottom: true,
}))`
  font-weight: 600;
  color: white;
  @media (max-width: 600px) {
    overflow-wrap: break-word;
    font-size: 11vw;
  }
`;
